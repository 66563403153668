import * as React from "react";
import "./Tickets.css";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import ComboBox from "../Components/ComboBox";
import ComboBox2 from "../Components/ComboBox2";
import TabsWrappedLabel from "../Components/Tabs";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Drawer from "@mui/material/Drawer";
import Autocomplete from "@mui/material/Autocomplete";
import SearchIcon from "@mui/icons-material/Search";
import UnfoldMoreIcon from "@mui/icons-material/UnfoldMore";
import MultipleSelect from "../Components/MultiSelect";
import { useState } from "react";
import axios from "axios";
import { baseURL } from "../Components/Constant";
export default function BasicTextFields() {
  const ticktype = ["1", "2", "3"];
  const [refreshData, setRefreshData] = useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = React.useState(false);
  const [selectedPriorities, setSelectedPriorities] = useState(null);

  const handleSelectionChange = (selectedValues) => {
    setSelectedPriorities(selectedValues);
  };

  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  const [type, setType] = useState(null);
  const [priority, setPriority] = useState(null);

  // const sentimentalAnalysis = (data) => {
  //   const apiUrl = "http://78.47.24.144:5555/classify_text";
  //   const requestData = {
  //     text: data,
  //   };
  //   console.log(requestData);
  //   const response = axios
  //     .post(apiUrl, requestData, {
  //       headers: {
  //         "Content-Type": "application/json",
  //       },
  //     })
  //     .then((response) => {
  //       console.log("Response:", response.data);
  //       setSentiment(response.data);
  //       return response.data;
  //     })
  //     .catch((error) => {
  //       console.error("Error:", error);
  //     });

  //   // console.log("sentiment", response.data);
  // };

  const sentimentalAnalysis = async (data) => {
    const apiUrl = "http://78.47.24.144:5555/classify_text";
    const requestData = {
      text: data,
    };

    console.log(requestData);

    // Return the promise from axios.post()
    return axios
      .post(apiUrl, requestData, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        console.log("Response:", response.data);
        // Assuming response.data contains the sentiment value

        return response.data; // Return the sentiment data
      })
      .catch((error) => {
        console.error("Error:", error);
        // You might want to throw the error here to propagate it to the caller
        throw error;
      });
  };

  const handleSubmit = async (event) => {
    console.log("submit clicked");
    event.preventDefault();

    const data = new FormData(event.currentTarget);

    const name = data.get("name");
    const type = data.get("type");
    // const priority = data.get("priority");
    const due_date = data.get("duedate");
    const ticketbody = data.get("ticketbody");
    const parsedDate = new Date(due_date); // Parse the string into a Date object
    const isoDate = parsedDate.toISOString(); // Convert to ISO-8601 format

    sentimentalAnalysis(ticketbody)
      .then((sentimentData) => {
        // Handle the sentiment data here, e.g., set priority state
        let newPriorityState = 0;
        if (sentimentData.sentiment >= -1 && sentimentData.sentiment <= 0.3) {
          newPriorityState = 1; // High priority
        } else if (
          sentimentData.sentiment > 0.3 &&
          sentimentData.sentiment <= 0.5
        ) {
          newPriorityState = 2; // Medium priority
        } else if (
          sentimentData.sentiment > 0.5 &&
          sentimentData.sentiment <= 1
        ) {
          newPriorityState = 3; // Low priority
        }

        console.log("sentiment data", newPriorityState);
        const requestBody = {
          name: name,
          typeId: Number(type),
          priorityId: newPriorityState,
          due_date: isoDate,
          description: ticketbody,
          userId: 1,
          statusId: 1,
        };
        console.log("req", requestBody);

        axios
          .post(`${baseURL}ticket`, requestBody)
          .then((response) => {
            setRefreshData((prev) => !prev);
          })
          .catch((error) => {
            console.error("Ticket creation: Failed", error);
          });
      })
      .catch((error) => {
        // Handle any errors that occur during sentiment analysis
        console.error("Error during sentiment analysis:", error);
      });

    // console.log("priority", sentiment);

    // console.log("priorityState", priorityState);

    // console.log("req", requestBody);

    // console.log("name :", name);
    // console.log("type :", type);
    // console.log("priority : ", priority);
    // console.log("date :", due_date);
    // console.log("ticketbody :", ticketbody);
  };

  return (
    <div className="ticketsbox">
      <Box
        component="form"
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          "& > :not(style)": { m: 1 },
          marginLeft: "40px",
        }}
        noValidate
        autoComplete="off"
      >
        <TextField
          id="ostandard-basic"
          label="Search for tickets"
          variant="standard"
          sx={{
            width: 250,

            borderRadius: 1,
            "& .MuiInputLabel-root": {
              color: "#7F56D8",
            },
          }}
          InputProps={{
            endAdornment: (
              <SearchIcon
                sx={{ color: "#7F56D8", cursor: "pointer", marginRight: "5%" }}
              />
            ),
          }}
        />
        <div
          style={{
            display: "flex",
            direction: "row",
          }}
        >
          <MultipleSelect onSelectionChange={handleSelectionChange} />
          <ComboBox2 />
          <Stack
            spacing={2}
            direction="row"
            sx={{
              height: "50px",
              bgcolor: "#7F56D8",
              borderRadius: 5,
              marginTop: "8px",
            }}
          >
            <Button
              variant="contained"
              sx={{
                width: "157px",

                bgcolor: "#7F56D8",
              }}
              onClick={toggleDrawer}
            >
              New Ticket
            </Button>
          </Stack>
        </div>
      </Box>
      <TabsWrappedLabel
        refresh={refreshData}
        selectedPriorities={selectedPriorities}
      />
      <Drawer anchor="right" open={isDrawerOpen} onClose={toggleDrawer}>
        <form
          onSubmit={handleSubmit}
          noValidate
          style={{
            marginTop: 10,
            paddingLeft: 15,
            paddingRight: 15,
            flexDirection: "column",
            justifyContent: "space-between",
            height: "100%",
          }}
        >
          <div
            style={{
              width: 400,

              margin: "15px",
              fontSize: "18px",
              fontWeight: 600,
              color: "#7F56D8",
            }}
          >
            Create a Quick Ticket
          </div>

          <div
            style={{
              width: 400,
              height: "",
              marginLeft: "15px",
              fontSize: "14px",
              fontWeight: 500,
              color: "#7F56D8",
            }}
          >
            Write and address new queries and issues
          </div>

          <div
            style={{
              width: 400,
              height: "",
              margin: "15px",
              fontSize: "15px",
              fontWeight: 500,
              color: "#7F56D8",
              marginTop: "30px",
            }}
          >
            Customer Name
          </div>
          <TextField
            required
            name="name"
            id="filled-basic"
            label="Name"
            variant="filled"
            sx={{
              width: "100%",
              // maxWidth: 400,
              bgcolor: "#FBFBFB",
              // marginLeft: "15px",
              fontWeight: 500,

              bgcolor: "rgba(127, 86, 216, 0.1)",
            }}
          />
          <div
            style={{
              width: 400,

              margin: "15px",
              fontSize: "15px",
              fontWeight: 500,
              color: "#7F56D8",
            }}
          >
            Request Ticket type
          </div>

          <Autocomplete
            required
            disablePortal
            name="type"
            id="combo-box-demo"
            options={ticktype}
            sx={{
              // maxWidth: 400,
              bgcolor: "rgba(127, 86, 216, 0.1)",
              // marginLeft: "15px",
            }}
            renderInput={(params) => (
              <TextField {...params} name="type" label="Choose Type" />
            )}
            onChange={(event, value) => {
              const intValue = parseInt(value); // Convert value to integer
              setType(intValue);
              // Do something with intValue...
            }}
          />
          {/* <div
            style={{
              width: 400,
              height: "",
              margin: "15px",
              fontSize: "15px",
              fontWeight: 500,
              color: "#7F56D8",
            }}
          >
            Priority Status
          </div>
          <Autocomplete
            required
            disablePortal
            name="priority"
            id="combo-box-demo"
            options={statustype}
            sx={{
              // maxWidth: 400,

              bgcolor: "rgba(127, 86, 216, 0.1)",
              // marginLeft: "15px",
            }}
            renderInput={(params) => (
              <TextField {...params} name="priority" label="Select Status" />
            )}
            onChange={(event, value) => {
              const intValue = parseInt(value); // Convert value to integer
              setPriority(intValue);
              // Do something with intValue...
            }}
          /> */}

          <div
            style={{
              width: 400,
              height: "",
              margin: "15px",
              fontSize: "15px",
              fontWeight: 500,
              color: "#7F56D8",
              marginTop: "10px",
            }}
          >
            Due Date
          </div>
          <TextField
            required
            name="duedate"
            id="filled-basic"
            variant="filled"
            type="date"
            sx={{
              width: "100%",
              bgcolor: "#FBFBFB",
              // marginLeft: "15px",
              fontWeight: 500,
              bgcolor: "rgba(127, 86, 216, 0.1)",
            }}
          />
          <div
            style={{
              width: 400,
              height: "",
              margin: "15px",
              fontSize: "15px",
              fontWeight: 500,
              color: "#7F56D8",
            }}
          >
            Ticket Body
          </div>
          <TextField
            required
            name="ticketbody"
            id="outlined-basic"
            label="Type your issue here"
            variant="outlined"
            sx={{
              width: "100%",

              // maxWidth: 400,
              // height: "auto",
              // marginLeft: "15px",
              fontWeight: 500,
              color: "#2E2A40",
              bgcolor: "rgba(127, 86, 216, 0.1)",
            }}
          />

          {/* <Button
            variant="contained"
            type="submit"
            onClick={toggleDrawer}
            sx={{
              marginTop: "50px",
              width: "100%",
              // width: 400,
              height: "60px",
              // position: "fixed",

              // marginLeft: "15px",
              bgcolor: "#AE8EF1",
            }}
          >
            Submit
          </Button> */}
          <Button
            variant="contained"
            type="submit"
            onClick={toggleDrawer}
            sx={{
              marginTop: "20px",
              bgcolor: "#AE8EF1",
              width: "100%",
              height: "40px",
            }}
          >
            Submit
          </Button>
        </form>
      </Drawer>
    </div>
  );
}
