import * as React from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import CssBaseline from "@mui/material/CssBaseline";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import Person3Icon from "@mui/icons-material/Person3";
import { Route, Routes, createBrowserRouter } from "react-router-dom";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import BasicTextFields from "./Tickets";
import GridViewIcon from "@mui/icons-material/GridView";
import LocalActivityIcon from "@mui/icons-material/LocalActivity";
import SupervisorAccountIcon from "@mui/icons-material/SupervisorAccount";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import CreateTick from "./CreateTick";
import TickDetail from "./TickDetail";
import { AppRoutesRegistry } from "../Routes/AppRoutesRegistry";
const drawerWidth = 249;

export default function PermanentDrawerLeft() {
  const navigate = useNavigate();

  const handleNavigateToCreate = () => {
    navigate("/dashboard/createticket");
  };
  const handleNavigateToTickets = () => {
    navigate("/dashboard/");
  };
  const handleNavigateToDetail = () => {
    navigate("/dashboard/details");
  };
  const handleNavigateToLogin = () => {
    navigate("/");
  };

  // const [activeWindow, setActiveWindow] = useState("dash");
  return (
    <>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <AppBar
          position="fixed"
          sx={{
            width: `calc(100% - ${drawerWidth}px)`,
            ml: `${drawerWidth}px`,
            height: "85px",
            bgcolor: "#FFFFFF",
          }}
        >
          <Toolbar>
            <Typography variant="h6" noWrap component="div">
              {/* <IconButton
                size="large"
                edge="start"
                color="inherit"
                aria-label="menu"
                sx={{
                  color: "#2E2C34",

                  mr: 2,
                }}
              >
                <MenuIcon />
              </IconButton> */}
            </Typography>
            <Typography
              variant="h6"
              component="div"
              sx={{
                flexGrow: 1,

                color: "#7F56D8",
              }}
            >
              Welcome! User.
            </Typography>
            <Button
              color="inherit"
              sx={{
                width: "208px",
                height: "56px",
                top: "14px",
                bgcolor: "rgba(127, 86, 216, 0.1)",
                color: "#7F56D8",
                border: "1px solid ",
                padding: "12px 18px 12px 5px",
                borderRadius: "1000px",
                gap: "12px",
              }}
              onClick={handleNavigateToLogin}
            >
              Login
            </Button>
          </Toolbar>
        </AppBar>
        <Drawer
          sx={{
            width: drawerWidth,
            flexShrink: 0,
            "& .MuiDrawer-paper": {
              width: drawerWidth,
              boxSizing: "border-box",
            },
          }}
          variant="permanent"
          anchor="left"
        >
          {" "}
          <Toolbar sx={{ fontSize: "24px", color: "#7F56D8" }}>
            Intelligent TBMS
          </Toolbar>
          <List>
            <ListItem disablePadding>
              <ListItemButton
                // onClick={() => setActiveWindow("dash")}
                sx={{
                  color: "#7F56D8",
                  "&:hover": { backgroundColor: "rgba(127, 86, 216, 0.1)" },
                }}
              >
                <ListItemIcon>
                  <GridViewIcon
                    sx={{
                      color: "#7F56D8",
                    }}
                  />
                </ListItemIcon>
                <ListItemText primary={"Dashboard"} />
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton
                // onClick={() => setActiveWindow("users")}
                sx={{
                  color: "#7F56D8",

                  "&:hover": { backgroundColor: "rgba(127, 86, 216, 0.1)" },
                }}
              >
                <ListItemIcon>
                  <Person3Icon
                    sx={{
                      color: "#7F56D8",
                    }}
                  />
                </ListItemIcon>
                <ListItemText
                  primary={"Create Ticket"}
                  onClick={handleNavigateToCreate}
                />
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton
                // onClick={() => setActiveWindow("tickets")}
                sx={{
                  color: "#7F56D8",
                  "&:hover": { backgroundColor: "rgba(127, 86, 216, 0.1)" },
                }}
              >
                <ListItemIcon>
                  <LocalActivityIcon
                    sx={{
                      color: "#7F56D8",
                    }}
                  />
                </ListItemIcon>
                <ListItemText
                  primary={"Tickets"}
                  onClick={handleNavigateToTickets}
                />
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              {/* <ListItemButton
                // onClick={() => setActiveWindow("officials")}
                sx={{
                  color: "#7F56D8",
                  "&:hover": { backgroundColor: "rgba(127, 86, 216, 0.1)" },
                }}
              >
                <ListItemIcon>
                  <SupervisorAccountIcon
                    sx={{
                      color: "#7F56D8",
                    }}
                  />
                </ListItemIcon>
                <ListItemText
                  primary={"Ticket Details"}
                  onClick={handleNavigateToDetail}
                />
              </ListItemButton> */}
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton
                // onClick={() => setActiveWindow("officials")}
                sx={{
                  color: "#7F56D8",
                  "&:hover": { backgroundColor: "rgba(127, 86, 216, 0.1)" },
                }}
              >
                <ListItemIcon>
                  <SettingsOutlinedIcon
                    sx={{
                      color: "#7F56D8",
                    }}
                  />
                </ListItemIcon>
                <ListItemText primary={"Site Settings"} />
              </ListItemButton>
            </ListItem>
          </List>
          <Divider />
        </Drawer>
        <Box component="main" sx={{ flexGrow: 1, bgcolor: "#F9F9FB", p: 3 }}>
          <Toolbar />
          <Typography
            sx={{ fontSize: "24px", marginTop: "8px", color: "#7F56D8" }}
          >
            Tickets
          </Typography>
        </Box>
      </Box>
      <Routes>
        <Route path="/" element={<BasicTextFields />} />
        <Route path="/details" element={<TickDetail />} />
        <Route path="/createticket" element={<CreateTick />} />
      </Routes>
      {/* <BasicTextFields /> */}
      {/* {activeWindow === "dash" && <BasicTextFields />}
      {activeWindow === "users"}
      {activeWindow === "tickets"}
      {activeWindow === "officials"} */}
    </>
  );
}
