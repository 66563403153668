import React, { useState } from "react";
import { Button } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import "./CreateTick.css";
import axios from "axios";
import { baseURL } from "../Components/Constant";
function CreateTick() {
  const ticktype = ["1", "2", "3"];
  const statustype = ["1", "2", "3"];
  const [type, setType] = useState(null);
  const [priority, setPriority] = useState(null);
  const handleSubmit = (event) => {
    event.preventDefault();

    const data = new FormData(event.currentTarget);
    const name = data.get("name");
    // const type = data.get("type");
    // const priority = data.get("priority");
    const due_date = data.get("duedate");
    const ticketbody = data.get("ticketbody");
    const parsedDate = new Date(due_date); // Parse the string into a Date object
    const isoDate = parsedDate.toISOString(); // Convert to ISO-8601 format
    const requestBody = {
      name: name,
      typeId: type,
      priorityId: priority,
      due_date: isoDate,
      description: ticketbody,
      userId: 1,
      statusId: 1,
    };
    // console.log(data);

    // console.log("name :", name);
    // console.log("type :", type);
    // console.log("priority : ", priority);
    // console.log("date :", due_date);
    // console.log("ticketbody :", ticketbody);

    axios
      .post(`${baseURL}ticket`, requestBody)
      .then((response) => {})
      .catch((error) => {
        console.error("Ticket creation: Failed", error);
      });
  };

  return (
    <div className="tickbox">
      <div
        style={{
          width: 400,
          height: "",
          margin: "15px",
          fontSize: "18px",
          fontWeight: 600,
          color: "#7F56D8",
        }}
      >
        Create a Quick Ticket
      </div>
      <div
        style={{
          width: 400,
          height: "",
          marginLeft: "15px",
          fontSize: "14px",
          fontWeight: 500,
          color: "#7F56D8",
        }}
      >
        Write and address new queries and issues
      </div>
      <form onSubmit={handleSubmit} noValidate style={{ marginTop: 10 }}>
        <div className="fields">
          <div>
            <div style={{ color: "#7F56D8" }}>Customer Name</div>
            <TextField
              required
              name="name"
              id="filled-basic"
              label="Type Name"
              variant="filled"
              sx={{ width: 350, bgcolor: "rgba(127, 86, 216, 0.1)" }}
            />
          </div>

          <div>
            <div style={{ color: "#7F56D8" }}>Request Ticket Type</div>
            <Autocomplete
              required
              name="type"
              disablePortal
              id="combo-box-demo"
              options={ticktype}
              renderInput={(params) => (
                <TextField {...params} name="type" label="Choose Type" />
              )}
              onChange={(event, value) => {
                const intValue = parseInt(value); // Convert value to integer
                setType(intValue);
                // Do something with intValue...
              }}
              sx={{ width: 350, bgcolor: "rgba(127, 86, 216, 0.1)" }}
            />
          </div>

          <div>
            <div style={{ color: "#7F56D8" }}>Priority Status</div>
            <Autocomplete
              required
              name="priority"
              disablePortal
              id="combo-box-demo"
              options={statustype}
              renderInput={(params) => (
                <TextField {...params} name="priority" label="Select Status" />
              )}
              onChange={(event, value) => {
                const intValue = parseInt(value); // Convert value to integer
                setPriority(intValue);
                // Do something with intValue...
              }}
              sx={{ width: 350, bgcolor: "rgba(127, 86, 216, 0.1)" }}
            />
          </div>
        </div>
        <div className="datefield">
          <div style={{ color: "#7F56D8" }}>Due Date</div>
          <TextField
            required
            name="duedate"
            id="filled-basic"
            type="date"
            variant="filled"
            sx={{ width: 350, bgcolor: "rgba(127, 86, 216, 0.1)" }}
          />
        </div>
        <div
          style={{
            width: 400,
            height: "",
            margin: "15px",
            fontSize: "15px",
            fontWeight: 500,
            color: "#7F56D8",
          }}
        >
          Ticket Body
        </div>
        <TextField
          required
          name="ticketbody"
          id="filled-multiline-static"
          label="Type Ticket issue here"
          multiline
          rows={4}
          columns={4}
          defaultValue=""
          variant="outlined"
          sx={{ width: "95%", color: "#7F56D8 " }}
        />
        <Button
          variant="contained"
          type="submit"
          sx={{ marginTop: "20px", bgcolor: "#AE8EF1", width: 157 }}
        >
          Submit
        </Button>
      </form>
    </div>
  );
}

export default CreateTick;
