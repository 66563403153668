import { Route, Routes, createBrowserRouter } from "react-router-dom";
import { AppRoutesRegistry } from "./AppRoutesRegistry";
import Login from "../Pages/Login";
import React from "react";
import MainPage from "../Pages/MainPage";
const AppRouter = () => {
  return (
    <Routes>
    <Route path="/" element={<Login />} />
    <Route path="/dashboard/*" element={<MainPage />} />
  </Routes>
  );
};

export default AppRouter;
