import BasicTextFields from "../Pages/Tickets";
import TickDetail from "../Pages/TickDetail";
import CreateTick from "../Pages/CreateTick";
import Login from "../Pages/Login";
export const AppRoutesRegistry = [
  {
    label: "dashboard",
    routeObject: {
      path: "/",
      Component: BasicTextFields,
    },
  },
  {
    label: "",
    routeObject: {
      path: "/details",
      Component: TickDetail,
    },
  },
  {
    label: "Materials",
    routeObject: {
      path: "/createticket",
      Component: CreateTick,
    },
  },
  {
    label: "tickets",
    routeObject: {
      path: "/tickets",
      Component: BasicTextFields,
    },
  },
];
