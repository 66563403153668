import * as React from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";

export default function ComboBox2() {
  return (
    <Autocomplete
      disablePortal
      id="combo-box-demo"
      options={time}
      sx={{
        width: 117,
        marginTop: "8px",
        marginRight: "15px",
        "& .MuiInputLabel-root": {
          color: "#7F56D8",
        },
        "& .MuiInputBase-root": {
          height: 50,

          bgcolor: "rgba(127, 86, 216, 0.1)",
        },
      }}
      renderInput={(params) => <TextField {...params} label="This week" />}
    />
  );
}

const time = [
  { label: "Today" },
  { label: "This Week" },
  { label: "This Month" },
];
