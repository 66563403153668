import * as React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import MailIcon from "@mui/icons-material/Mail";
import MarkEmailUnreadIcon from "@mui/icons-material/MarkEmailUnread";
import ForwardToInboxIcon from "@mui/icons-material/ForwardToInbox";
import UpcomingIcon from "@mui/icons-material/Upcoming";
import TabPanel from "@mui/lab/TabPanel";
import TabContext from "@mui/lab/TabContext";
import TicketList from "./TicketList";
import { useEffect, useState } from "react";
import axios from "axios";
import { baseURL } from "./Constant";
import Card from "./Card";
export default function TabsWrappedLabel({ refresh, selectedPriorities }) {
  const [apiData, setApiData] = useState([]);
  const [apiData1, setapiData1] = useState([]);
  const [apiData2, setapiData2] = useState([]);
  const [apiData3, setapiData3] = useState([]);
  // const [childData, setChildData] = useState(null);
  // console.log("Priority Data : ", selectedPriorities);
  const [dataFromChild, setDataFromChild] = useState(false); // State to store data from child

  // Callback function to handle data from child
  const handleChildData = (data) => {
    setDataFromChild((prev) => !prev);
    // setDataFromChild(data); // Update the state with data from the child
    // console.log("received data", data);
  };
  // console.log("data in state", dataFromChild);

  useEffect(() => {
    fetchData();
    fetchData1();
    fetchData2();
    fetchData3();
    fetchPriorityData();
  }, [refresh, dataFromChild, selectedPriorities]);
  // const receiveDataFromChild = (data) => {
  //   // Update the state with the received data
  //   setChildData(data);
  //   console.log("received Data", childData);
  // };

  const fetchData = async () => {
    try {
      const response = await axios.get(`${baseURL}ticket`);
      setApiData(response.data);
      // console.log("response : ", response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  // console.log(apiData);

  const fetchPriorityData = async () => {
    try {
      if (selectedPriorities) {
        const response = await axios.get(
          `${baseURL}ticket/priority/${selectedPriorities}`
        );
        setApiData(response.data);
      }
      // console.log("response : ", response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  // console.log(apiData);

  const fetchData1 = async () => {
    try {
      const response = await axios.get(`${baseURL}ticket/status/1`);
      setapiData1(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  // console.log(apiData1);

  const fetchData2 = async () => {
    try {
      const response = await axios.get(`${baseURL}ticket/status/2`);
      setapiData2(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  // console.log(apiData2);

  const fetchData3 = async () => {
    try {
      const response = await axios.get(`${baseURL}ticket/status/3`);
      setapiData3(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const [value, setValue] = React.useState("one");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: "100%", typography: "body1" }}>
      <TabContext value={value}>
        <Box sx={{ width: "100%" }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="wrapped label tabs example"
          >
            <Tab
              value="one"
              label={
                <>
                  <MailIcon /> All tickets
                </>
              }
              sx={{
                display: "flex",
                width: "150px",
                marginLeft: "40px",
                color: "#7F56D8",
                "&:hover": {
                  backgroundColor: "rgba(127, 86, 216, 0.1)",
                },
                borderRadius: "5px",
              }}
            />
            <Tab
              value="two"
              label={
                <>
                  <MarkEmailUnreadIcon /> New
                </>
              }
              sx={{
                width: "150px",
                marginLeft: "40px",
                color: "#7F56D8",
                "&:hover": {
                  backgroundColor: "rgba(127, 86, 216, 0.1)",
                },
                borderRadius: "5px",
              }}
            />
            <Tab
              value="three"
              label={
                <>
                  <ForwardToInboxIcon /> On-Going
                </>
              }
              sx={{
                width: "150px",
                marginLeft: "40px",
                color: "#7F56D8",
                "&:hover": {
                  backgroundColor: "rgba(127, 86, 216, 0.1)",
                },
                borderRadius: "5px",
              }}
            />
            <Tab
              value="four"
              label={
                <>
                  <UpcomingIcon /> Resolved
                </>
              }
              sx={{
                width: "150px",
                marginLeft: "40px",
                color: "#7F56D8",
                "&:hover": {
                  backgroundColor: "rgba(127, 86, 216, 0.1)",
                },
                borderRadius: "5px",
              }}
            />
          </Tabs>
        </Box>
        <TabPanel value="one">
          {apiData.map((ticket, index) => (
            // sentimentalAnalysis(ticket.description),
            <Card
              key={index}
              ticketName={ticket.name}
              ticketTitle={ticket.ticketTitle}
              description={ticket.description}
              personName={ticket.personName}
              openTicket={ticket.link}
              ticketId={ticket.id}
              sendDataToParent={handleChildData}
            />
          ))}

          {/* <TicketList data={apiData} /> */}
        </TabPanel>
        <TabPanel value="two">
          {apiData1.map((ticket, index) => (
            <Card
              key={index}
              ticketName={ticket.name}
              ticketTitle={ticket.ticketTitle}
              description={ticket.description}
              personName={ticket.personName}
              openTicket={ticket.link}
              ticketId={ticket.id}
              sendDataToParent={handleChildData}
            />
          ))}
        </TabPanel>
        <TabPanel value="three">
          {apiData2.map((ticket, index) => (
            <Card
              key={index}
              ticketName={ticket.name}
              ticketTitle={ticket.ticketTitle}
              description={ticket.description}
              personName={ticket.personName}
              openTicket={ticket.link}
              ticketId={ticket.id}
              sendDataToParent={handleChildData}
            />
          ))}
        </TabPanel>
        <TabPanel value="four">
          {apiData3.map((ticket, index) => (
            <Card
              key={index}
              ticketName={ticket.name}
              ticketTitle={ticket.ticketTitle}
              description={ticket.description}
              personName={ticket.personName}
              openTicket={ticket.link}
              ticketId={ticket.id}
              sendDataToParent={handleChildData}
            />
          ))}
        </TabPanel>
      </TabContext>
    </Box>
  );
}
