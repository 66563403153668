import React from "react";
import "./TickDetail.css";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from "axios";
import { baseURL } from "../Components/Constant";

function TickDetail() {
  const [apiData, setApiData] = useState([]);
  const location = useLocation();
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [selectedPriority, setSelectedPriority] = useState(null);
  const [selectedType, setSelectedType] = useState(null);
  // Extract ticketId from location state
  const ticketId = location.state && location.state.ticketId;

  const tickettype = [
    { label: "1", value: 1 },
    { label: "4", value: 2 },
    { label: "8", value: 3 },
  ];
  const supportofficer = [
    { label: "Ahmad Shoaib" },
    { label: "Yousaf Ali" },
    { label: "Waleed" },
  ];
  const prioritytype = [
    { label: "High", value: 1 },
    { label: "Average", value: 2 },
    { label: "Low", value: 3 },
  ];
  const stattype = [
    { label: "new", value: 1 },
    { label: "ongoing ", value: 2 },
    { label: "resolved", value: 3 },
  ];
  // console.log("ticketID : ", ticketId);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await axios.get(`${baseURL}ticket/${ticketId}`);
      setApiData(response.data);
      setSelectedStatus(response.data.statusId);
      setSelectedPriority(response.data.priorityId);
      setSelectedType(response.data.typeId);

      // console.log("response : ", response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  // console.log("newapidata", apiData);
  return (
    <div className="detailbox">
      <h1 style={{ fontSize: "16px", margin: "25px", color: "#7F56D8" }}>
        Ticket #{apiData.id}
      </h1>
      <h2 style={{ fontSize: "18px", margin: "25px", color: "#7F56D8" }}>
        {apiData.name}
      </h2>
      <p style={{ fontSize: "14px", margin: "25px", color: "#7F56D8" }}>
        {apiData.description}
      </p>
      <div className="autofields">
        <div>
          <div style={{ color: "#7F56D8" }}>Support Officer</div>
          <Autocomplete
            disablePortal
            id="combo-box-demo"
            options={supportofficer}
            renderInput={(params) => (
              <TextField {...params} label="Choose Type" />
            )}
            style={{ width: 250, backgroundColor: "rgba(127, 86, 216, 0.2)" }}
          />
        </div>
        <div>
          <div style={{ color: "#7F56D8" }}> Status</div>
          <Autocomplete
            disablePortal
            id="combo-box-demo"
            options={stattype}
            value={stattype[selectedStatus - 1] || ""} // Set the initial value based on the selectedStatus state
            onChange={(event, value) => setSelectedStatus(value)}
            renderInput={(params) => (
              <TextField {...params} label="Select Status" />
            )}
            style={{ width: 250, backgroundColor: "rgba(127, 86, 216, 0.2)" }}
          />
        </div>
        <div>
          <div style={{ color: "#7F56D8" }}>Priority </div>
          <Autocomplete
            disablePortal
            id="combo-box-demo"
            options={prioritytype}
            value={prioritytype[selectedPriority - 1] || ""}
            renderInput={(params) => (
              <TextField {...params} label="Select Priority" />
            )}
            style={{ width: 250, backgroundColor: "rgba(127, 86, 216, 0.2)" }}
          />
        </div>
        <div>
          <div style={{ color: "#7F56D8" }}>Type</div>
          <Autocomplete
            disablePortal
            id="combo-box-demo"
            options={tickettype}
            value={tickettype[selectedType - 1] || ""}
            renderInput={(params) => (
              <TextField {...params} label="Select Type" />
            )}
            style={{ width: 250, backgroundColor: "rgba(127, 86, 216, 0.2)" }}
          />
        </div>
      </div>
      <div
        style={{
          width: 400,
          height: "",
          marginTop: "50px",
          marginLeft: "25px",

          margin: "15px",

          fontSize: "15px",
          fontWeight: 600,
          color: "#7F56D8",
        }}
      >
        Ticket Body
      </div>
      <TextField
        id="filled-multiline-static"
        label="Type Ticket issue here"
        multiline
        rows={4}
        columns={4}
        defaultValue=""
        variant="outlined"
        sx={{
          marginLeft: "15px",
          width: "80%",
          backgroundColor: "rgba(127, 86, 216, 0.2)",
        }}
      />
      <Button
        variant="contained"
        sx={{
          width: "157",
          height: "44px",
          position: "fixed",
          bottom: "1px",
          marginBlockEnd: "10px",
          right: "15px",
          bgcolor: "#7F56D8",
          color: "#FFFFFF",
        }}
      >
        Assign Support
      </Button>
    </div>
  );
}

export default TickDetail;
