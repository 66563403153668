import React, { useState } from "react";
import "./Card.css";
import { useNavigate } from "react-router-dom";
import { baseURL } from "./Constant";
import axios from "axios";

function Card({
  ticketName,
  ticketTitle,
  description,
  personName,
  ticketId,
  sendDataToParent,
}) {
  const [refreshData, setRefreshData] = useState(true);
  const handleClick = () => {
    sendDataToParent(refreshData); // Call the parent callback with data
  };
  const navigate = useNavigate();

  const handleDeleteTicket = async () => {
    try {
      const response = await axios.delete(`${baseURL}ticket/${ticketId}`);
      // console.log("response : ", response);
      handleClick();
    } catch (error) {
      console.error("Error occurred while deleting ticket:", error);
    }
  };

  const handleNavigateToDetail = () => {
    // console.log("navigateTicketID : ", ticketId);
    navigate("/dashboard/details", { state: { ticketId } });
  };

  return (
    <div className="card">
      <h2>{ticketName}</h2>
      <h3>{ticketTitle}</h3>
      <p className="descriptionp">{description}</p>
      <hr></hr>

      <p className="personname">
        {personName}
        <button
          style={{ cursor: "pointer" }}
          onClick={handleNavigateToDetail}
          className="cardlink"
        >
          Open Ticket
        </button>
        <button
          style={{ cursor: "pointer" }}
          onClick={handleDeleteTicket}
          className="cardlink"
        >
          Delete Ticket
        </button>
      </p>
    </div>
  );
}

export default Card;
