import * as React from "react";
import { useTheme } from "@mui/material/styles";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const names = [
  { label: "high", value: 1 },
  { label: "average", value: 2 },
  { label: "low", value: 3 },
];

// function getStyles(name, personName, theme) {
//   return {
//     fontWeight:
//       personName.indexOf(name) === -1
//         ? theme.typography.fontWeightRegular
//         : theme.typography.fontWeightMedium,
//   };
// }

export default function MultipleSelect({ onSelectionChange }) {
  const theme = useTheme();
  const [personName, setPersonName] = React.useState([]);

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setPersonName(typeof value === "string" ? value.split(",") : value);
    onSelectionChange(value);
    // console.log("multiselect : ", value);
  };

  return (
    <div>
      <FormControl
        sx={{
          m: 1,

          minWidth: 150,
          maxWidth: 450,
          marginRight: "15px",
          "& .MuiInputLabel-root": {
            color: "#7F56D8",
          },
          "& .MuiInputBase-root": {
            height: 50,

            bgcolor: "rgba(127, 86, 216, 0.1)",
          },
        }}
      >
        <InputLabel id="demo-multiple-name-label">Priority</InputLabel>
        <Select
          labelId="demo-multiple-name-label"
          id="demo-multiple-name"
          value={personName}
          onChange={handleChange}
          input={<OutlinedInput label="Name" />}
          MenuProps={MenuProps}
        >
          {names.map((name) => (
            <MenuItem
              key={name.value}
              value={name.value}
              // style={getStyles(name, personName, theme)}
            >
              {name.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}
