import "./App.css";
import MainPage from "./Pages/MainPage";
import { BrowserRouter } from "react-router-dom";
import AppRouter from "./Routes/AppRouter";
import Login from "./Pages/Login";
import { Route, Routes, createBrowserRouter } from "react-router-dom";
function App() {
  return (
    <>
      <BrowserRouter>
     <AppRouter/>
    </BrowserRouter>
    </>
  );
}

export default App;
