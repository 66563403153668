import React from "react";
import PermanentDrawerLeft from "./PermanentDrawerLeft";

function MainPage() {
  return (
    <>
      <PermanentDrawerLeft />
    </>
  );
}

export default MainPage;
